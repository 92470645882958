.inspector {
    font-family: monospace;
    display: flex;

    &__game-objects {
        width: 265px;
        background: red;
    }

    &__selected {
        width: 100%;
        background: green;
        padding: 10px;
    }
}

.game-objects-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &__item {
        button {
            width: 100%;
            text-align: left;

            &.selected {
                font-weight: bold;
            }
        }
    }
}

.component-list {
    &__item {
        background-color: antiquewhite;
        margin: 0 0 10px 0;
    }
}
