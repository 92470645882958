@import 'inspector';

.game {
    @media screen and (min-width: 820px) {
        display: flex;
    }

    &__view {
        flex: 1;
    }

    &__scene {
        background-color: azure;

        canvas {
            margin: 0 auto;
            display: block;
        }
    }

    &__physics {
        background-color: azure;

        canvas {
            margin: 0 auto;
            display: block;
        }
    }

    &__inspector {
        flex: 1;
        background-color: beige;
    }
}
